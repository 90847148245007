import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProductService } from 'src/app/_services/product.service';
import { URLS } from '../../_config/api.config';
import { FormComponent } from '../../_guards/can-deactivate-guard';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
import { UploadImageComponent } from '../../components/upload-image/upload-image.component';

@Component({
	selector: 'app-product-configuration',
	templateUrl: './product-configuration.component.html',
	styleUrls: ['./product-configuration.component.scss'],
})
export class ProductConfigurationComponent implements OnInit, FormComponent {
	step = 2;
	validateForm = new FormGroup({});
	dataSaved = false;
	public productForm: FormGroup;
	public subscription = new Subscription();
	public isConversionRatio = false;
	productId: any;
	productList = [];
	product: any;
	isEditPage = false;
	isDraftPage = false;
	productChanged: boolean = false;
	conversionRatio: any;
	constructor(
		private modalService: ModalService,
		private alertController: AlertController,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private productService: ProductService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private toastService: ToastService,
		@Inject(DOCUMENT) private document: Document
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		this.productId = this.activeRoute.snapshot.paramMap.get('id');

		if (element) {
			element.scrollIntoView();
		}
		let selectedWarehouse = localStorage.getItem('selectedWarehouse');
		if (selectedWarehouse) {
			this.productService.selectedWarehouseList = JSON.parse(selectedWarehouse);
		}
		if (this.router.url.includes('/product/edit')) {
			this.isEditPage = true;
			this.productChanged = false;
		} else {
			this.isEditPage = false;
		}
		if (this.productService.savedProduct) {
			this.product = this.productService.savedProduct;
			this.isConversionRatio = this.product.conversation_ratio ? this.product.conversation_ratio : null;
			this.conversionRatio = this.product.conversation_ratio ? this.product.conversation_ratio : null;
			this.productList = [];
			this.productList =
				this.product && this.product.skus && this.product.skus.length > 0
					? this.product.skus
					: [];
			this.productService.selectedWarehouse = [];
			this.productList.map((item, index) => {
				if (item.inventory && item.inventory.length) {
					item.inventory.map((invItem) => {
						item[invItem.warehouse_id] = invItem.available_quantity
							? invItem.available_quantity
							: 0;
						if (index == 0) {
							if (
								this.productService.selectedWarehouseList.includes(
									invItem.warehouse_id
								)
							) {
								this.productService.selectedWarehouse.push({
									id: invItem.warehouse_id,
									name: invItem.warehouse_title_en,
									value: null,
								});
							}
						}
					});
				}
			});
		} else {
			if (!this.productId) {
				this.dataSaved = true;
				this.router.navigate([
					'/account-details/products-management/product/add-new-product/product-detail',
				]);
				return;
			}
			this.getProductDetails(this.productId);
		}
	}

	next() {
		let noImageAvail = false;
		let isWebCoverNotSet = false;
		let isMobileCoverNotSet = false;
		let isWeightNotSet = false;
		if (this.isConversionRatio && !this.conversionRatio) {
			const CONVERSION_RATIO_REGEX = /^(?!0(\.0+)?$)(0|[1-9]\d*)(\.\d+)?$/;
			if (!CONVERSION_RATIO_REGEX.test(this.conversionRatio)) {
				this.toastService.displayToast('error.conversionError', null, 'danger');
				return;
			}
		}
		this.productService.savedProduct.skus.map((x) => {
			if (!noImageAvail) {
				if (
					!x['product_images_mobile'] ||
					!x['product_images_web'] ||
					(x['product_images_mobile'] && !x['product_images_mobile'].length) ||
					(x['product_images_web'] && !x['product_images_web'].length)
				) {
					noImageAvail = true;
				}
				if (!noImageAvail) {
					if (
						!isMobileCoverNotSet &&
						x['product_images_mobile'] &&
						x['product_images_mobile'].length
					) {
						let mobileCoverObj = x['product_images_mobile'].find(
							(img) => img.is_cover_image
						);
						if (!mobileCoverObj) {
							isMobileCoverNotSet = false;
							x['product_images_mobile'][0]['is_cover_image'] = true;
						}
					}
					if (
						!isWebCoverNotSet &&
						x['product_images_web'] &&
						x['product_images_web'].length
					) {
						let webCoverObj = x['product_images_web'].find(
							(img) => img.is_cover_image
						);
						if (!webCoverObj) {
							isWebCoverNotSet = false;
							x['product_images_web'][0]['is_cover_image'] = true;
						}
					}
				}
			}
			if (!x['product_weight']) {
				isWeightNotSet = true;
			}
		});
		if (noImageAvail) {
			this.toastService.displayToast('error.allImage', null, 'danger');
			return;
		}
		if (isWebCoverNotSet) {
			this.toastService.displayToast('error.webCover', null, 'danger');
			return;
		}
		if (isMobileCoverNotSet) {
			this.toastService.displayToast('error.mobileCover', null, 'danger');
			return;
		}
		if (isWeightNotSet) {
			this.toastService.displayToast('error.productWeight', null, 'danger');
			return;
		}
		if (this.conversionRatio) {
			const CONVERSION_RATIO_REGEX = /^(?!0(\.0+)?$)(0|[1-9]\d*)(\.\d+)?$/;
			if (!CONVERSION_RATIO_REGEX.test(this.conversionRatio)) {
				this.toastService.displayToast('error.conversionError', null, 'danger');
				return;
			}
		}
		if (this.isEditPage == true && this.productChanged != true) {
			this.dataSaved = true;
			this.commonService.localStorageSet('secondStepEditPage', false);
			this.router.navigate([
				'account-details/products-management/product/edit/product-preview',
				this.productService.savedProduct.product_id,
			]);
			return;
		}
		this.commonService.localStorageSet('secondStepEditPage', true);
		noImageAvail = false;
		if (this.isEditPage) {
			this.productService
				.updateProductVariations(true, false, true)
				.then((data) => {
					this.dataSaved = true;
					this.productService
						.addInventory(this.productService.warehouseQuantity)
						.then(
							(data) => {
								this.router.navigate([
									'account-details/products-management/product/edit/product-preview',
									this.productService.savedProduct.product_id,
								]);
							},
							(error) => {
								this.router.navigate([
									'account-details/products-management/product/edit/product-preview',
									this.productService.savedProduct.product_id,
								]);
							}
						);
				});
		} else {
			this.productService.updateProductVariations(true).then((data) => {
				this.dataSaved = true;
				this.productService
					.addInventory(this.productService.warehouseQuantity)
					.then(
						(data) => {
							this.router.navigate([
								'account-details/products-management/product/add-new-product/product-preview',
								this.productService.savedProduct.product_id,
							]);
						},
						(error) => {
							this.router.navigate([
								'account-details/products-management/product/add-new-product/product-preview',
								this.productService.savedProduct.product_id,
							]);
						}
					);
			});
		}
	}

	prev() {
		this.dataSaved = true;
		if (this.isEditPage) {
			this.router.navigate([
				'account-details/products-management/product/edit/product-detail',
				this.productService.savedProduct.product_id,
			]);
		} else {
			this.router.navigate([
				'account-details/products-management/product/add-new-product/product-detail',
				this.productService.savedProduct.product_id,
			]);
		}
		this.commonService.setEvent('productStep', {
			isStep: 0,
		});
	}

	openUploadImageModal(columnName, values, title, item) {
		if (this.productService.modalSave) {
			return;
		}
		let options: any = {
			id: 'uploadImageModal',
			column: columnName,
			cssClass: 'upload-image-modal',
			backdropDismiss: false,
			componentProps: {
				isCommonImage: this.product.is_common_images_to_all_sku,
				imagesArray: values,
				product_id: this.product.product_id,
				title: title + ' ' + columnName + ' images',
				imageType: columnName,
				productVariation: item,
			},
		};
		options['component'] = UploadImageComponent;
		this.modalService.openModal(
			options,
			this.productService.updateProductVariations.bind(
				this.productService,
				true
			)
		);
		this.productService.updateFromModal = true;
	}

	getProductDetails(id) {
		const params = {
			product_id: id,
		};
		this.subscription.add(
			this.dataService.get(URLS.productDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.product = data.data;
						this.isConversionRatio = this.product.conversation_ratio ? this.product.conversation_ratio : null;
						this.conversionRatio = this.product.conversation_ratio ? this.product.conversation_ratio : null;
						this.product.brand_name = this.productService.mapIdWithValue(
							this.productService.brandList,
							'id',
							this.product.brand_id
						);
						this.product.product_unit_name = this.productService.mapIdWithValue(
							this.productService.unitsList,
							'id',
							this.product.unit_id
						);
						this.product.availability_name = this.productService.mapIdWithValue(
							this.productService.stockAvailablityList,
							'id',
							this.product.availability_id
						);
						this.productService.savedProduct = data.data;
						this.productList = [];
						this.productList =
							this.product && this.product.skus && this.product.skus.length > 0
								? this.product.skus
								: [];
						this.productService.selectedWarehouse = [];
						this.productList.map((item, index) => {
							if (
								item.product_images_mobile &&
								item.product_images_mobile.length
							) {
								let obj = item.product_images_mobile.find(
									(el) => el.is_cover_image
								);
								item.mobile_cover_img = obj ? true : false;
							}
							if (item.product_images_web && item.product_images_web.length) {
								let obj = item.product_images_web.find(
									(el) => el.is_cover_image
								);
								item.web_cover_img = obj ? true : false;
							}
							if (this.productService.savedProduct.skus && this.productService.savedProduct.skus.length) {
								this.productService.savedProduct.skus.forEach(el => {
									if (!this.productService.savedProduct.conversation_ratio) {
										el['conversation_ratio'] = null;
									}
								});
							}

							if (item.inventory && item.inventory.length) {
								item.inventory.map((invItem) => {
									item[invItem.warehouse_id] = invItem.available_quantity
										? invItem.available_quantity
										: 0;
									if (index == 0) {
										if (
											this.productService.selectedWarehouseList.includes(
												invItem.warehouse_id
											)
										) {
											this.productService.selectedWarehouse.push({
												id: invItem.warehouse_id,
												name: invItem.warehouse_title_en,
												value: null,
											});
										}
									}
								});
							}
						});
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	arabicOnlyValidation(event: any) {
		const pattern = /[\u0600-\u06FF\u0750-\u077F 0-9-]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}

	decimalCheck(event) {
		var charCode = event.which ? event.which : event.keyCode;
		if (
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			!(charCode == 46 || charCode == 8)
		) {
			return false;
		}
		if (event.key == '.' && event.target.value.includes(event.key)) {
			return false;
		}
		var dotPos = event.target.value.indexOf('.');
		if (
			event.target.value.includes('.') &&
			dotPos == event.target.value.length - 3
		) {
			return false;
		}
		return true;
	}

	changeDetected(event) {
		this.productChanged = true;
		if (event.target.value) {
			this.conversionRatio = event.target.value;
			if (this.conversionRatio) {
				this.isConversionRatio = true;
			}
		}
		if (event.target.value == "") {
			this.conversionRatio = null;
		}
	}

	async presentAlert(product) {
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR
				? 'Are you sure?'
				: 'هل أنت متأكد؟',
			message: this.commonService.domDirectionLTR
				? 'You want to delete product.'
				: 'تريد حذف المنتج.',
			buttons: [
				{
					text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
					role: 'cancel',
					handler: () => { },
				},
				{
					text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
					role: 'confirm',
					handler: () => {
						product.is_active = false;
						product.is_deleted = true;
						if (this.isEditPage) {
							this.productService
								.updateProductVariations(false, false, true, {}, true)
								.then((data) => {
									this.getProductDetails(this.productId);
								});
						} else {
							this.productService
								.updateProductVariations(false, false, false, {}, true)
								.then((data) => {
									this.getProductDetails(this.productId);
								});
						}
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}
}
