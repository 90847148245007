import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';
import { CreditConfirmationComponent } from '../credit-confirmation/credit-confirmation.component';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	public subscription = new Subscription();
	isLoading = false;
	orderId: any;
	orderData: any;
	showLoader: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private router: Router,
		private navCtrl: NavController,
		private activeRoute: ActivatedRoute,
		private alertCtrl: AlertController,
		private modalCtrl: ModalController
	) {
		this.orderId = this.activeRoute.snapshot.paramMap.get('id');
	}

	ngOnInit() {
		if (this.orderId) {
			this.getOrderData(this.orderId);
		}
	}


	getOrderData(id: any) {
		this.showLoader = true;
		const params = {
			invoice_id: id,
		};
		this.subscription.add(
			this.dataService.get(URLS.orderDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.orderData = {
								shipping_address: data['data']['shipping_address'] ? data['data']['shipping_address'] : '-',
								billing_address: data['data']['billing_address'] ? data['data']['billing_address'] : '-',
								billing_address_city: data['data']['billing_address_city'] ? data['data']['billing_address_city'] : '',
								billing_address_country: data['data']['billing_address_country'] ? data['data']['billing_address_country'] : '',
								billing_address_region: data['data']['billing_address_region'] ? data['data']['billing_address_region'] : '',
								billing_address_zipcode: data['data']['billing_address_zipcode'] ? data['data']['billing_address_zipcode'] : '',
								shipping_address_city: data['data']['shipping_address_city'] ? data['data']['shipping_address_city'] : '',
								shipping_address_country: data['data']['shipping_address_country'] ? data['data']['shipping_address_country'] : '',
								shipping_address_region: data['data']['shipping_address_region'] ? data['data']['shipping_address_region'] : '',
								shipping_address_zipcode: data['data']['shipping_address_zipcode'] ? data['data']['shipping_address_zipcode'] : '',
								customer_email: data['data']['customer_email'] ? data['data']['customer_email'] : '-',
								customer_name: data['data']['customer_name'] ? data['data']['customer_name'] : '-',
								quotation_no: data['data']['quotation_no'],
								mobile_no: data['data']['mobile_no'] ? data['data']['mobile_no'] : '-',
								discount_applied: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'total_discount') : 0,
								coupon_code: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'coupon_code') : 0,
								order_date: data['data']['order_date'] ? data['data']['order_date'] : '',
								order_id: data['data']['order_no'] ? data['data']['order_no'] : '-',
								paid_order_amount: data['data']['paid_order_amount'] ? data['data']['paid_order_amount'] : '-',
								payment_type: data['data']['payment_method'] ? data['data']['payment_method'] : '-',
								credit_approval_status: data['data']['credit_approval_status'] ? data['data']['credit_approval_status'] : '-',
								shipping_charge: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'shipping_charges') : 0,
								total_order_amount: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'total_price') : 0,
								vat_amount: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'vat') : 0,
								vat_percent: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'vat_percentage') : 0,
								total_cal: data['data']['total_commission'] ? data['data']['total_commission'] : '',
								supplier_name: this.commonService.domDirectionLTR ? data['data']['supplier_name_en'] : data['data']['supplier_name_ar'],
								order_details: data['data']['order_details'],
								admin_comm: data['data']['admin_comm'],
								invoice_id: data['data']['invoice_id'],
								waybill_no: data['data']['waybill_no'],
								shipping_status: data['data']['shipping_status'] ? data['data']['shipping_status'] : 'CONFIRMED',
								item_subtotal: data['data']['grand_total'] && data['data']['grand_total'].length ? this.getValueByKey(data, 'items_sub_total') : 0,
								grand_total: data['data']['grand_total'] ? data['data']['grand_total'] : null,
								credit_time_days: data['data']['credit_time_days'] ? data['data']['credit_time_days'] : null,
								credit_due_date: data['data']['credit_due_date'] ? data['data']['credit_due_date'] : null
							}
							if (this.orderData.grand_total && this.orderData.grand_total.length) {
								this.orderData.grand_total.sort((a, b) => a.sequence - b.sequence);
							}
						}
						this.showLoader = false;
					} else {
						this.showLoader = false;
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
						this.navCtrl.navigateBack(['/account-details/orders-management']);
					}
				},
				(error) => {
					this.navCtrl.navigateBack(['/account-details/orders-management']);
					this.showLoader = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	getValueByKey(data, key) {
		const grandTotal = data['data']['grand_total'];

		// Find the object with the specified key
		const item = grandTotal.find(item => item.key === key);

		// Return the value or '-' if it doesn't exist
		return item && item.value !== undefined ? item.value : '-';
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

	shippingStatus(orderData, productLevel?) {
		let status = orderData.shipping_status;
		switch (status) {
			case 'NOT_DELIVERED':
			case 'NOT_YET_DELIVERED':
				return 'btn-grey product-status-btn'
			case 'DISPATCHED':
			case 'CONFIRMED':
				return 'btn-green product-status-btn'
			case 'DELIVERED':
				return 'btn-black product-status-btn'
			case 'CANCELLED':
				return 'btn-red product-status-btn'
			case 'RETURN_REQUESTED':
			case 'CANCELLATION_REQUESTED':
			case 'RETURNED':
				return 'btn-orange return-request-btn'
			default:
				return 'btn-green product-status-btn'
		}
	}
	shippingStatusLabel(orderData) {
		let status = orderData.shipping_status;
		switch (status) {
			case 'NOT_YET_DELIVERED':
			case 'NOT_DELIVERED':
				return 'notYetDelivered';
			case 'DISPATCHED':
				return 'dispatched';
			case 'CONFIRMED':
				return 'confirmed';
			case 'DELIVERED':
				return 'delivered';
			case 'CANCELLED':
				return 'canceled';
			case 'RETURN_REQUESTED':
				return 'returnRequested'
			case 'CANCELLATION_REQUESTED':
				return 'cancellationRequested'
			case 'RETURNED':
				return 'returned'
			default:
				return '-'
		}
	}

	async orderStatusChange(orderData) {
		orderData.invoice_id = +this.orderId;
		const modal = await this.modalCtrl.create({
			component: CreditConfirmationComponent,
			cssClass: 'details-modal',
			backdropDismiss: false,
			componentProps: { orderData: orderData ? orderData : null }
		});
		modal.onDidDismiss().then(data => {
			if (data['data']) {
				this.getOrderData(this.orderId);
			}
		})
		modal.present();
	}

}
