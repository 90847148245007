import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
	constructor() { }

	@HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
		e.preventDefault();
	}

}