import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";

export class CustomValidator {
	// Validates URL
	static urlValidator(url): any {
		if (url.value === null || url.value === "") return null;
		if (url.pristine) {
			return null;
		}
		const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
		url.markAsTouched();
		if (URL_REGEXP.test(url.value)) {
			return null;
		}
		return {
			invalidUrl: true
		};
	}
	// Validates passwords
	static matchPassword(group: FormGroup): any {
		const password = group.controls.password;
		const confirm = group.controls.confirm;
		if (password.pristine || confirm.pristine) {
			return null;
		}
		group.markAsTouched();
		if (password.value === confirm.value) {
			return null;
		}
		return {
			invalidPassword: true
		};
	}

	static confirmPassword(input: any): any {
		let pass: any
		if (input.parent) {
			if (input.parent.controls.password && input.parent.controls.password.value) {
				pass = input.parent.controls.password.value
			} else if (input.parent.controls.new_password && input.parent.controls.new_password.value) {
				pass = input.parent.controls.new_password.value
			}
		}
		if (input.pristine || input.parent.controls.password) {
			if (input.pristine || input.parent.controls.password.pristine) {
				return null;
			}
		}
		if (input.pristine || input.parent.controls.new_password) {
			if (input.pristine || input.parent.controls.new_password.pristine) {
				return null;
			}
		}
		if (!pass && !input.value) {
			return null;
		}
		if (!input.value) {
			return null;
		}
		if (pass && input.value && pass == input.value) {
			return null;
		}
		return {
			passMismatch: true
		};
	}
	// Validates numbers
	static numberValidator(number): any {
		if (number.pristine) {
			return null;
		}
		if (number.value == "" || number.value == null || number.value == undefined) {
			return null
		}
		const NUMBER_REGEXP = /^(0|[1-9]\d*)?$/;
		number.markAsTouched();
		if (number.value === "0000000000") {
			return {
				invalidNumber: true
			};
		}
		if (NUMBER_REGEXP.test(number.value)) {
			return null;
		}
		return {
			invalidNumber: true
		};
	}

	static decimalNumberValidator(number): any {
		if (number.errors && (number.errors.min || number.errors.max)) {
			return null;
		}
		if (number.pristine) {
			return null;
		}
		if (number.value == "" || number.value == null || number.value == undefined) {
			return null
		}
		const NUMBER_REGEXP = /^(0|[1-9]\d*)(\.\d+)?$/; // regex for decimal value
		// const NUMBER_REGEXP = /^(\d*)(\.\d+)?$/; // regex for decimal value
		// const NUMBER_REGEXP = /^[0-9\b]+$/; // regex for decimal value
		number.markAsTouched();
		if (NUMBER_REGEXP.test(number.value)) {
			return null;
		}
		if (+number.value < 0) {
			return null;
		}
		return {
			invalidNumber: true
		};
	}
	// Validates US SSN
	static ssnValidator(ssn): any {
		if (ssn.pristine) {
			return null;
		}
		const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
		ssn.markAsTouched();
		if (SSN_REGEXP.test(ssn.value)) {
			return null;
		}
		return {
			invalidSsn: true
		};
	}
	// Validates US phone numbers
	static phoneValidator(number): any {
		if (number.pristine) {
			return null;
		}
		const PHONE_REGEXP = /^(\+\d{1,3}[- ]?)?\d{9}$/;
		number.markAsTouched();
		if (number.value === "0000000000") {
			return {
				invalidNumber: true
			};
		}
		if (PHONE_REGEXP.test(number.value) || number.value === '' || number.value === null) {
			return null;
		}
		return {
			invalidNumber: true
		};
	}
	// Validates US phone numbers
	static landlineValidator(number): any {
		if (number.pristine) {
			return null;
		}
		const PHONE_REGEXP = /^(\+\d{1,3}[- ]?)?\d{10}$/;
		number.markAsTouched();
		if (number.value === "0000000000") {
			return {
				invalidNumber: true
			};
		}
		if (PHONE_REGEXP.test(number.value) || number.value === '' || number.value === null) {
			return null;
		}
		return {
			invalidNumber: true
		};
	}
	static crValidator(number): any {
		if (number.pristine) {
			return null;
		}
		const PHONE_REGEXP = /^(\+\d{1,3}[- ]?)?\d{10}$/;
		number.markAsTouched();
		if (number.value === "0000000000") {
			return {
				invalidNumber: true
			};
		}
		if (PHONE_REGEXP.test(number.value) || number.value === '' || number.value === null) {
			return null;
		}
		return {
			invalidNumber: true
		};
	}
	// Validates zip codes
	static zipCodeValidator(zip): any {
		if (zip.value === null || zip.value === "" || zip.value === undefined) return null;
		if (zip.pristine) {
			return null;
		}
		const ZIP_REGEXP = /^[1-9][0-9]{5}$/; // indian zip code
		// const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
		zip.markAsTouched();
		if (ZIP_REGEXP.test(zip.value)) {
			return null;
		}
		return {
			invalidZip: true
		};
	}
	// Vlidates password pattern
	static passwordPatternValidator(password): any {
		if (password.pristine) {
			return null;
		}
		if (!password.value) {
			return null;
		}
		const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,25}$/;
		password.markAsTouched();
		if (PASSWORD_REGEXP.test(password.value)) {
			return null;
		}
		return {
			invalidPassword: true
		};
	}

	//Validates Pan number
	static panNumberValidator(pan): any {
		if (pan.pristine) {
			return null;
		}
		// const PAN_REGEXP = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
		const PAN_REGEXP = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
		pan.markAsTouched();
		if (pan.value == '' || pan.value == null || pan.value == undefined) {
			return null
		}
		if (PAN_REGEXP.test(pan.value.trim())) {
			// if (pan.parent) {
			//   if (pan.parent.controls['lastName'].value) {
			//     if (pan.value.toLowerCase().charAt(4) == pan.parent.controls['lastName'].value.toLowerCase().charAt(0)) {
			//       return null;
			//     }
			//   }
			//   return {
			//     invalidPan: true
			//   };
			// }
			return null;
		}
		return {
			invalidPan: true
		};
	}

	// Validates Email ID
	static emailValidator(email): any {
		if (email.value === null || email.value === "") return null;
		if (email.pristine) {
			return null;
		}
		// const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

		// const EMAIL_REGEXP = /^(([a-zA-Z0-9_][^<>()\[\]\.,'?*%$!#&{}^;/|+:\s@\"]+(\.[^<>()\[\]\.,'?*%$!#&{}^;/|+:\s@\"]+)*)|(\".+\"))@(([a-zA-Z0-9_][^<>()[\]\.,'?*%$!#&{}^;/|+:\s@\"]+\.)+[a-zA-Z0-9_][^<>()[\]\.,'?*%$!#&{}^;/|+:\s@\"]{2,})$/;

		// const EMAIL_REGEXP = /(^[a-zA-Z0-9_.+-]+[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z-.]{2,}$)/;

		// const EMAIL_REGEXP = /^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))$/;

		const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
		email.markAsTouched();
		if (EMAIL_REGEXP.test(email.value.trim())) {
			return null;
		}
		return {
			invalidEmail: true
		};
	}

	// Validates Email ID
	static emailPhoneValidator(email): any {
		if (email.value === null || email.value === "") return null;
		if (email.pristine) {
			return null;
		}
		// const EMAIL_REGEXP = /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;

		// const EMAIL_REGEXP = /^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|\d{10}$/;

		// const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,5}|\d{10}$/;
		const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
		const phone = /^\d{9}$/

		email.markAsTouched();
		if (EMAIL_REGEXP.test(email.value) || phone.test(email.value)) {
			return null;
		}
		return {
			invalidEmailPhone: true
		};
	}


	// Validates Username and phone number
	static userNamePhoneValidator(data): any {
		if (data.value === null || data.value === '') {
			return null;
		}
		if (data.pristine) {
			return null;
		}
		const DATA_REGEXP = /^[a-z0-9_-]{3,25}$|^[7-9][0-9]{9}$/;
		data.markAsTouched();
		if (DATA_REGEXP.test(data.value)) {
			return null;
		}
		return {
			invaliddata: true
		};
	}

	static arabicAddressValidator(string): any {
		// string.value.trim() === " "
		if (string.value === null || string.value === "" || string.value.trim() === " ") return null;
		if (string.pristine) return null;
		// const NAME_REGEX = /^[\=\@\#\$\&\%\_\!\/\+\-\*\0-9\٠-٩\ء-ي]+$/gm;
		const NAME_REGEX = /[\u0600-\u06FF\u0750-\u077F]/;
		string.markAsTouched();
		if (NAME_REGEX.test(string.value.trim())) {
			return null;
		}
		return {
			invalidName: true
		}
	}

	// Validates white space
	static noWhiteSpaceValidator(string): any {
		if (string.errors && (string.errors.minlength || string.errors.maxlength || string.errors.required)) {
			return null;
		}
		if (string.value === null || string.value === "") return null;
		if (string.pristine) return null;
		string.markAsTouched();
		if (string.value.trim() == '') {
			return {
				invalidString: true
			}
		} else {
			return null
		}
		// const isWhitespace = (string.value || '').trim().length === 0;
		// const isValid = !isWhitespace;
		// return isValid ? null : { 'whitespace': true };
	}

	//no Space Validator
	static cannotContainSpace(string: AbstractControl): ValidationErrors | null {
		if ((string.value as string).indexOf(' ') >= 0) {
			return { cannotContainSpace: true }
		}

		return null;
	}

	// Validates double quotes
	static noQuotesValidator(string): any {
		if (string.value) {
			let isQuateInvalid = false;
			if (string.value.includes('\"') || string.value.includes("\'")) {
				isQuateInvalid = true;
			}
			return isQuateInvalid ? { 'quotes': true } : null;
		}
	}

	// Vlidates facebook pattern
	static facebookPatternValidator(facebook): any {
		if (facebook.value === null || facebook.value === "") return null;
		if (facebook.pristine) {
			return null;
		}
		const FACEBOOK_REGEXP = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/i;
		facebook.markAsTouched();
		if (FACEBOOK_REGEXP.test(facebook.value)) {
			return null;
		}
		return {
			invalidFacebook: true
		};
	}

	// Vlidates Twiter pattern
	static twiterPatternValidator(twiter): any {
		if (twiter.value === null || twiter.value === "") return null;
		if (twiter.pristine) {
			return null;
		}
		const TWITER_REGEXP = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
		twiter.markAsTouched();
		if (TWITER_REGEXP.test(twiter.value)) {
			return null;
		}
		return {
			invalidTwiter: true
		};
	}

	// Vlidates Instagram pattern
	static instagramPatternValidator(instagram): any {
		if (instagram.value === null || instagram.value === "") return null;
		if (instagram.pristine) {
			return null;
		}
		const INSTAGRAM_REGEXP = /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/i;
		instagram.markAsTouched();
		if (INSTAGRAM_REGEXP.test(instagram.value)) {
			return null;
		}
		return {
			invalidInstagram: true
		};
	}

	// Do not allow special characters
	static blockSpecialCharacter(string): any {
		if (string.value === null || string.value === "") return null;
		if (string.pristine) return null;
		const ONLY_ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9 ]+$/i;
		string.markAsTouched();
		if (ONLY_ALPHA_NUMERIC_REGEX.test(string.value)) {
			return null;
		}
		return {
			invalidString: true
		}
	}

	static nameValidator(string): any {
		if (string.value === null || string.value === "" || string.value.trim() === " ") return null;
		if (string.pristine) return null;
		const NAME_REGEX = /^[a-zA-Z']+(?:[_.\s][a-zA-Z']+)*$/;
		string.markAsTouched();
		if (NAME_REGEX.test(string.value.trim())) {
			return null;
		}
		return {
			invalidName: true
		}
	}

	static alphanumericValidator(string): any {
		if (string.value === null || string.value === "" || string.value.trim() === " ") return null;
		if (string.pristine) return null;
		const NAME_REGEX = /^[a-zA-Z0-9']+(?:[_.\s][a-zA-Z0-9']+)*$/;
		string.markAsTouched();
		if (NAME_REGEX.test(string.value.trim())) {
			return null;
		}
		return {
			invalidName: true
		}
	}

	static nameValidatorMinCharacter(string): any {
		if (string.value === null || string.value.length < 2 || string.value === "" || string.value.trim() === " ") return null;
		if (string.pristine) return null;
		const NAME_REGEX = /^[a-zA-Z']+(?:[_.\s][a-zA-Z']+)*$/;
		string.markAsTouched();
		if (NAME_REGEX.test(string.value.trim())) {
			return null;
		}
		return {
			invalidName: true
		}
	}

	static arabicNameValidator(string): any {
		if (string.value === null || string.value === "" || !string.value) return null;
		if (string.pristine) return null;
		// const NAME_REGEX = /^[\u0621-\u064A0-9 ]+$/;
		const NAME_REGEX = /[\u0600-\u06FF\u0750-\u077F]/;
		string.markAsTouched();
		if (NAME_REGEX.test(string.value.trim())) {
			return null;
		}
		return {
			invalidName: true
		}
	}

	// Validates vat certificate numbers
	static vatNoValidator(number): any {
		if (number.pristine) {
			return null;
		}
		const PHONE_REGEXP = /^(\+\d{1,3}[- ]?)?\d{15}$/;
		number.markAsTouched();
		if (number.value === "000000000000000") {
			return {
				invalidNumber: true
			};
		}
		if (PHONE_REGEXP.test(number.value) || number.value === '' || number.value === null) {
			return null;
		}
		return {
			invalidNumber: true
		};
	}

	// Password validators
	static areEqual(formGroup: FormGroup) {
		let val;
		let valid = true;

		for (let key in formGroup.controls) {
			if (formGroup.controls.hasOwnProperty(key)) {
				let control: FormControl = <FormControl>formGroup.controls[key];

				if (val === undefined) {
					val = control.value
				} else {
					if (val !== control.value) {
						valid = false;
						break;
					}
				}
			}
		}

		if (valid) {
			return null;
		}

		return {
			areEqual: true
		};
	}

	// do not allow 0 as first input
	static invalidConversionRatio(string): any {
		if (string.value === null || string.value === "") return null;
		if (string.pristine) return null;

		// Updated regex to allow only numbers greater than 0
		const CONVERSION_RATIO_REGEX = /^(?!0(\.0+)?$)(0|[1-9]\d*)(\.\d+)?$/;

		string.markAsTouched();
		if (CONVERSION_RATIO_REGEX.test(string.value)) {
			return null;
		}
		return {
			invalidString: true
		};
	}

}
