import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-view-customer-rating',
	templateUrl: './view-customer-rating.component.html',
	styleUrls: ['./view-customer-rating.component.scss'],
})
export class ViewCustomerRatingComponent implements OnInit {
	public subscription = new Subscription();
	@Input() invoice_id: any;

	customerDetails: any;
	customerInvoice: any;


	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private dataService: ApiService,
		public commonService: CommonService,
		private modalService: ModalService,
		private modalCtrl: ModalController,
		private toastService: ToastService,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.getCustomerCreditsDetails();
	}

	ionViewWillEnter() {
		this.subscription = new Subscription();
	}

	onDateChange(datetime) {
		datetime.confirm(true);
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}



	close(updated?) {
		this.modalCtrl.dismiss(updated);
	}


	getCustomerCreditsDetails() {
		let params = {
			invoice_id: this.invoice_id
		}
		this.subscription.add(
			this.dataService.get(URLS.getCustomerCreditDetails, params).subscribe(data => {
				if (data['code'] == 200) {
					let mainData = data['data'];
					if (mainData && mainData.credit_orders_summary) {
						this.customerDetails = { ...mainData, ...mainData.credit_orders_summary };
						this.customerInvoice = mainData.credit_invoice_history;
					}
				} else {
					this.close();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.close();
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}
}
